.alternative-layout {
    .home.container {
        .container__grid {
            display: flex;
            align-items: stretch;
            /* or flex-start if you want them to align at the top */
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;


            .grid__item {
                width: 25%;
                max-width: 700px;
                display: flex;
                /* Height property removed */
                align-items: center;
                flex-direction: column;
                /* Justify-content property removed */
                flex-wrap: wrap;
                background: rgb(20, 20, 20);
                outline: 1px solid $tarkov-gold;
                margin: 20px;
                padding: 20px;
                box-shadow: 0 0 20px 0 rgba(154, 136, 102, 0.1);
                transition: 100ms ease all;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .home.container {
            .container__grid {
                align-items: center;
                flex-direction: column;

                .grid__item {
                    width: 80%;
                    margin-top: 10% !important;
                    margin-bottom: 0;
                }
            }

            .container__seperator {
                height: 500px;
            }
        }
    }
}