@import "vars.scss";
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

@font-face {
    font-family: benderLight;
    src: local("./font/Jovanny Lemonad - Bender-Light.otf");
}

:root {
    font-family: 'Rajdhani', sans-serif;
}

body {
    overflow: hidden;
}

.more {
    font-size: 1em;
    width: auto;
    color: $tarkov-gold;
    outline: none;
    border: none;
    background: transparent;
    margin-bottom: 20px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.loading-sub__box {
    // width: 300px;
    // height: 300px;
    // background: #1a237e;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.3);
}

.loading-sub__container {
    height: 15px;
    width: 105px;
    display: flex;
    position: relative;
    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $tarkov-gold;
        animation: move 500ms linear 0ms infinite;
        margin-right: 30px;

        &:first-child {
            position: absolute;
            top: 0;
            left: 0;
            animation: grow 500ms linear 0ms infinite;
        }

        &:last-child {
            position: absolute;
            top: 0;
            right: 0;
            margin-right: 0;
            animation: grow 500ms linear 0s infinite reverse;
        }
    }
}

@keyframes grow {
    from {
        transform: scale(0, 0);
        opacity: 0;
    }
    to {
        transform: scale(1, 1);
        opacity: 1;
    }
}

@keyframes move {
    from {
        transform: translateX(0px);
    }
    to {
        transform: translateX(45px);
    }
}


@mixin button-default {
    font-size: 1.2em;
    text-decoration: none;
    width: auto;
    padding: 10px 20px;
    background: $tarkov-gold;
    height: auto;
    border-radius: 5px;
    color: black;
    margin: 20px;
    filter: brightness(80%);
    transition: 100ms ease all;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
        filter: brightness(100%);
    }
}

@mixin disclaimer {
    width: 100%;
    text-align: center;
    font-size: 0.8em;
    padding: 10px 0;
    background: rgb(35, 35, 35);
}

a {
    color: $tarkov-gold;
}

.gold {
    color: $tarkov-gold;
}

.blur {
    filter: blur(20px);
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.container {
    width: 100vw;
    height: calc(100vh - $header-height);
    left: 0;
    top: $header-height;
    padding: 0;
    margin: 0;
    background: rgb(20, 20, 20);
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 10px;
        background: rgb(20, 20, 20);
    }

    &::-webkit-scrollbar-thumb {
        background: $tarkov-gold;
    }
}

.status {
    text-align: center;
    height: auto;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    background: rgb(35, 35, 35);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 5px;
    position: fixed;
}

.google_ads {
    width: 400px;
    padding: 0;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    background: rgba(154, 136, 102, 0.2);
    position: fixed;
    z-index: 99;
}

.custom__text__post {
    padding: 20px;
    margin-top: 20px;
    color: white;
    border-radius: 5px;
    background: rgb(50, 50, 50);

    .time {
        color: $tarkov-gold;
    }

    .comment {
        display: block;
        font-size: 0.75em;
    }
}

.event.container {
    .container__info {
        display: flex;
        justify-content: top;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        .custom__text__post {
            width: 70%;
            max-width: 700px;
            background: rgb(20, 20, 20);
            outline: 1px solid $tarkov-gold;
            margin: 20px;
            padding: 20px;
            border-radius: 0;
            box-shadow: 0 0 20px 0 rgba(154, 136, 102, 0.1);
            transition: 100ms ease all;

            img {
                max-width: 80%;
            }
        }
    }
}

.invis {
    visibility: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

@import "./header.scss";
@import "../pages/home/home.scss";
@import "../pages/info/info.scss";

@import "./alt.scss";