.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: 50px;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    background: rgb(15,15,15);
    border-bottom: 1px solid $tarkov-gold;
    position: fixed;
    z-index: 99;
    box-shadow: 0 0 50px -5px rgb(154, 136, 102);
    .header__title {
        text-decoration: none;
        color: $tarkov-gold;
    }
    .header__ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
        .ul__li {
            padding: 20px;
            .li__Link {
                font-size: 1.3em;
                text-decoration: none;
                color: $tarkov-gold;
                padding: 0 10px;
                border-radius: 5px;
                transition: 100ms ease all;
                &:hover {
                    color: black;
                    background: $tarkov-gold;
                }
            }
        }
    }
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: none;
        outline: none;
        border: none;
        position: relative;
        cursor: pointer;
        transition: 100ms ease all;
        &::after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: rgba(255,255,255,0.1);
            transform: scale(0);
            position: absolute;
            transition: 100ms ease all;
        }
        &:hover {
            &::after {
                transform: scale(1);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .header {
        .header__title {
            display: none;
        }
    }
}
@media screen and (max-width: 360px) {
    .header {
        height: 100px;
    }
    .container {
        top: 100px;
    }
}
@media screen and (max-width: 355px) {
    .header {
        .header__ul {
            .ul__li {
                padding: 5px;
            }
        }
    }
}