.home.container {
    .container__grid {
        display: flex;
        justify-content: top;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        .grid__item {
            width: 70%;
            max-width: 700px;
            display: flex;
            height: auto;
            align-items: center;
            flex-direction: column;
            justify-content: top;
            flex-wrap: wrap;
            background: rgb(20, 20, 20);
            outline: 1px solid $tarkov-gold;
            margin: 20px;
            padding: 20px;
            box-shadow: 0 0 20px 0 rgba(154, 136, 102,0.1);
            transition: 100ms ease all;
        }

        .item__a {
            width: 100%;
            text-decoration: none;
            cursor: pointer;
            color: white;
            padding: 10px;
            background: $tarkov-gold;
            border-radius: 5px;
            transition: 100ms ease all;
            &:hover {
                text-decoration: underline;
                filter: brightness(105%);
            }
        }
        .item__link {
            text-align: center;
            width: 100%;
            text-decoration: none;
            cursor: pointer;
            color: white;
            padding: 10px;
            margin: 20px 0 20px 0;
            background: rgba(255,255,255,0.2);
            border-radius: 5px;
            transition: 100ms ease all;
            &:hover {
                text-decoration: underline;
                background: rgba(255,255,255,0.3);
            }
        }

        .item__p-container {
            padding: 20px;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: top;
            align-items: center;
            flex-direction: column;
            .item__p {
                color: white;
            }
        }
        img {
            max-width: 80%;
        }
        .item__more {
            font-size: 1em;
            font-weight: 300;
            width: auto;
            text-decoration: none;
            cursor: pointer;
            color: white;
            padding: 10px;
            margin: 20px 0 20px 0;
            outline: none;
            border: none;
            background: none;
            border-radius: 5px;
            transition: 100ms ease all;
            &:hover {
                text-decoration: underline;
                background: $tarkov-gold;
            }
        }
        .item__expand {
            display: none;
            color: white;
            a {
                color: $tarkov-gold;
            }
        }
        .open {
            display: block;
        }
        .closed {
            display: none;
        }
        .item__date {
            color: white;
            padding: 5px 10px;
            background: rgba(255,255,255,0.2);
            border-radius: 5px;
        }
    }
    .container__seperator {
        height: 500px;
    }
}

@media screen and (max-width: 600px) {
    .home.container {
        .container__grid {
            align-items: center;
            flex-direction: column;
            .grid__item {
                width: 80%;
                margin-top: 10% !important;
                margin-bottom: 0;
            }
        }
        .container__seperator {
            height: 500px;
        }
    }
}