@import "../pages/home/alt.scss";
@import "../pages/info/alt.scss";

.alternative-layout {
    .container {
        width: 100vw;
        height: calc(100vh - $header-height);
        left: 0;
        top: $header-height;
        padding: 0;
        margin: 0;
        background: rgb(20, 20, 20);
        position: absolute;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 10px;
            background: rgb(20, 20, 20);
        }

        &::-webkit-scrollbar-thumb {
            background: $tarkov-gold;
        }
    }

    .status {
        text-align: center;
        height: auto;
        color: white;
        padding: 5px 20px;
        border-radius: 5px;
        background: rgb(35, 35, 35);
        transform: translate(-50%, 0);
        left: 50%;
        bottom: 5px;
        position: fixed;
    }

    .google_ads {
        width: 400px;
        padding: 0;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0;
        background: rgba(154, 136, 102, 0.2);
        position: fixed;
        z-index: 99;
    }

    .custom__text__post {
        padding: 20px;
        margin-top: 20px;
        color: white;
        border-radius: 5px;
        background: rgb(50, 50, 50);

        .time {
            color: $tarkov-gold;
        }

        .comment {
            display: block;
            font-size: 0.75em;
        }
    }

    .event.container {
        .container__info {
            display: flex;
            justify-content: top;
            align-items: center;
            flex-direction: column;
            flex-wrap: wrap;

            .custom__text__post {
                width: 70%;
                max-width: 700px;
                background: rgb(20, 20, 20);
                outline: 1px solid $tarkov-gold;
                margin: 20px;
                padding: 20px;
                border-radius: 0;
                box-shadow: 0 0 20px 0 rgba(154, 136, 102, 0.1);
                transition: 100ms ease all;

                img {
                    max-width: 80%;
                }
            }
        }
    }

    .invis {
        visibility: hidden;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }
}