.alternative-layout {

    .info.container,
    .predictions.container {
        display: flex;
        justify-content: top;
        align-items: center;
        flex-direction: column;

        .container__seperator {
            height: 500px;
            position: relative;
            background: red;
        }

        .container__links {
            margin: 20px 10px 10px 10px;
            display: flex;
            justify-content: center;
            align-items: top;
            flex-direction: row;
            flex-wrap: wrap;

            .seperator {
                width: 90%;
                height: 1px;
                display: block;
                background: rgb(85, 85, 85);
            }

            .links__Link {
                @include button-default;
            }
        }

        .info__container {
            width: 90%;
            display: flex;
            justify-content: top;
            align-items: center;
            flex-direction: column;
            padding: 10px;
            border-top: 1px solid grey;
            margin: 10px;
            position: relative;

            a {
                font-size: 1.1em;
                text-decoration: none;
                width: auto;
                margin: 20px 10px 10px 10px;
                padding: 10px 20px;
                height: auto;
                border-radius: 5px;
                color: $tarkov-gold;

                &:hover {
                    color: black;
                    background: $tarkov-gold;
                }
            }
        }
    }

    .prediction.container {
        display: flex;
        justify-content: top;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        .container__info {
            width: 80%;
            margin-top: 10vh;
            color: white;
            background: rgb(25, 25, 25);
            padding: 10px;
            outline: 1px solid $tarkov-gold;
            box-shadow: 0 0 20px 0 rgba(154, 136, 102, 0.1);

            .info__p {
                padding: 10px;

                span {
                    color: $tarkov-gold;
                }

                .disclaimer {
                    @include disclaimer;
                }

                .p__images {
                    display: flex;
                    align-items: top;
                    justify-content: center;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    height: auto;
                    position: relative;

                    img {
                        width: 45%;
                        min-width: 300px;
                        height: auto;
                        margin: 10px;
                    }
                }
            }
        }
    }

    .container__grid.pcodes {
        flex-direction: row !important;
        flex-wrap: wrap !important;
        align-items: top;
        padding-top: 20px;
        justify-content: center;

        .disclaimer {
            @include disclaimer;
            font-size: 1.2em;
            padding: 15px 0;
            color: white;
            width: 80%;
            margin-top: 5vh;
            margin-bottom: 20px;
        }

        .grid__item {
            flex-direction: row !important;
            justify-content: space-around;
            width: 40% !important;
            padding: 5px 5% !important;

            .item__status {
                padding: 5px 10px;
                border-radius: 1px;
                background: rgb(55, 55, 55);
                outline: 1px solid $tarkov-gold;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
                transition: 100ms ease all;
                cursor: default;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;

                &:hover {
                    box-shadow: 0 0 10px 0 $tarkov-gold;
                }
            }

            .item__button {
                @include button-default;

                font-size: 1em;
            }
        }
    }
}